import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../components/layout";
import "./page1.scss";
import finn_image from '../../img/finn-no-cape.png'
import ace_image from '../../img/Ace.png'
import dakota_image from '../../img/Dakota.png'
import wynn_image from '../../img/Wynn.png'
import { getBaseUrl, getClient, getGlobalVar, setGlobalVar, focusElement, isMobileDevice, sendGoogleAnalytics } from "../../components/globalFunctions";

const Page1 = ({ }) => {
    let { event_id } = useParams();
    let { cons_id } = useParams();

    const [cprHqUrl, setCprHqUrl] = useState('');

    const [selectedImagePicked, setSelectedImagePicked] = useState(false);
    const [selectedImageData, setSelectedImageData] = useState('');
    const [selectedImageType, setSelectedImageType] = useState('');
    const [isUploading, setIsUploading] = useState(false);
    const [imageSelection, setImageSelection] = useState('');
    const [includeDucks, setIncludeDucks] = useState(false);
    const [selectedImageLocalUrl, setSelectedImageLocalUrl] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const navigate = useNavigate();

    useEffect(() => {
        setTimeout(()=>{focusElement('layout_hq_button')},150)
        
        setCprHqUrl(getGlobalVar('cpr-hq-url'))
        setImageSelection(getGlobalVar('imageSelection'))
        if(getGlobalVar('imageSelection') == 'CUSTOM'){
            clickOnLoad('uploadPhotoOption',0)
            setSelectedImageType(getGlobalVar('selectedImageType'))
            setSelectedImagePicked(true)
        }else if (getGlobalVar('imageSelection') == 'FINN'){
            clickOnLoad('finnOption',0)
        }else if (getGlobalVar('imageSelection') == 'ACE'){
            clickOnLoad('aceOption',0)
        }else if (getGlobalVar('imageSelection') == 'WYNN'){
            clickOnLoad('wynnOption',0)
        }else if (getGlobalVar('imageSelection') == 'DAKOTA'){
            clickOnLoad('dakotaOption',0)
        }
        sendGoogleAnalytics('aha_cpr_s1_page_load', 'aha_cpr_s1')
        var id_string = process.env.REACT_APP_DISABLE_DUCKS_IDS
        if(id_string){
            var ids = id_string.split('|')
            if(ids.includes(event_id)){
                setIncludeDucks(false)
            }else{
                setIncludeDucks(true)
            }
        }else{
            setIncludeDucks(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const clickOnLoad = (id, i) => {
        if(document.getElementById(id)){
            document.getElementById(id).click()
        }else{
            if(i < 10){
                setTimeout(()=>{clickOnLoad(id, i + 1)}, i * 50)
            }
        }
    }

    const uploadButtonClicked = () => {
        sendGoogleAnalytics('aha_cpr_s1_upload_button', 'aha_cpr_s1')
        document.getElementById('customPhotoInput').click();
    };

    const nextButtonClicked = () => {
        setIsUploading(true)
        sendGoogleAnalytics('aha_cpr_s1_next_button', 'aha_cpr_s1')
        setErrorMessage("")
        var url = `${getBaseUrl()}/${getClient()}/ai/uploadPhoto`;
        let token = getGlobalVar('jwt_auth_token');

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("cons_id", cons_id);
        urlencoded.append("event_id", event_id);
        if (imageSelection == "CUSTOM") {
            urlencoded.append("image", selectedImageData);
        } 
        urlencoded.append("original_image_type", selectedImageType);
        urlencoded.append("character_type", imageSelection);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success && result.success.video_url) {
                    setGlobalVar('image_url', result.success.image_url)
                    setGlobalVar('video_url', result.success.video_url)
                    setGlobalVar('status', result.success.status)
                    setGlobalVar('imageSelection',imageSelection)
                    if(imageSelection == 'CUSTOM'){
                        setGlobalVar('selectedImageType',selectedImageType)
                        setGlobalVar('selectedImageLocalUrl',selectedImageLocalUrl)
                    }
                    navigate(`/editcprchallenge/${event_id}/${cons_id}/2`)
                } else {
                    setIsUploading(false)
                    console.log(result)
                    if(result.message == 'No face found in image.'){
                        setErrorMessage('Your photo should be in good lighting and be a close-up of a person’s face. Please upload a new photo and try again.')
                    }else{
                        setErrorMessage('There was an error uploading your photo, please try again later.')
                    }
                    if(isMobileDevice()){
                        focusErrorMessage(0);
                    }
                    //navigate(`/editcprchallenge/${event_id}/${cons_id}/5`)
                }
            })
            .catch(
                (error) => {
                    setIsUploading(false)
                    console.log(error)
                    //navigate(`/editcprchallenge/${event_id}/${cons_id}/5`)
                    setErrorMessage('There was an error uploading your photo, please try again later.')
                    if(isMobileDevice()){
                        focusErrorMessage(0);
                    }
                }
            );
    };

    const imageOptionClicked = (type) => {
        setImageSelection(type)
        if(type == "CUSTOM"){
            sendGoogleAnalytics('aha_cpr_s1_custom_option', 'aha_cpr_s1')
        }else if(type == 'ACE'){
            sendGoogleAnalytics('aha_cpr_s1_ace_option', 'aha_cpr_s1')
        }else if(type == 'WYNN'){
            sendGoogleAnalytics('aha_cpr_s1_wynn_option', 'aha_cpr_s1')
        }else if(type == 'DAKOTA'){
            sendGoogleAnalytics('aha_cpr_s1_dakota_option', 'aha_cpr_s1')
        }else{
            sendGoogleAnalytics('aha_cpr_s1_finn_option', 'aha_cpr_s1')
        }
    }

    const focusErrorMessage = (index) => {
        if(index < 10){
            if(document.getElementById('step_1_error')){
                document.getElementById('step_1_error').setAttribute('tabindex',0)
                document.getElementById('step_1_error').focus()
            }else{
                setTimeout(() => {focusErrorMessage(index + 1)}, 50 * index)
            }
        }
    }
 
    const imageLoadReply = () => {
        var image = document.getElementById('your_photo_preview')
        var canvas = document.createElement("canvas");
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
        var ctx = canvas.getContext("2d");  
        ctx.drawImage(image, 0, 0);
        let final = canvas.toDataURL();
        setSelectedImageData(final.toString());
    }

    const uploadPhoto = (event) => {
        let file_type = '';
        if(!event.target.files[0]){
            return
        }
        if (event.target.files[0].type.includes("png")) {
            file_type = 'PNG'
        } else if (event.target.files[0].type.includes("jpeg") || event.target.files[0].type.includes("jpg")) {
            file_type = 'JPEG'
        }

        if (event.target.files[0]) {
            if(event.target.files[0].size < 40000){
                setErrorMessage('Selected photo is too small. Please select a higher quality photo.')
                if(isMobileDevice()){
                    focusErrorMessage(0);
                }
            }else{
                setErrorMessage("")
                if (file_type != "") {
                    var file = event.target.files[0];
                    var reader = new FileReader();
                    setGlobalVar('selectImageFile',event.target.files[0])
                    setSelectedImageLocalUrl(URL.createObjectURL(event.target.files[0]));
                    reader.onloadend = function () {
                        setSelectedImagePicked(true)
                        setSelectedImageType(file_type);
                        if(isMobileDevice()){
                            setTimeout(() => {focusElement('aceOption',0)}, 50)
                            setTimeout(() => {focusElement('customPhotoInput',0)}, 150)
                        }
                        setTimeout(() => {focusElement('upload_button',0)}, 250)
                    }
                    reader.readAsDataURL(file);
                } else {
                    setErrorMessage("Please select a valid .png or .jpeg image")
                    if(isMobileDevice()){
                        focusErrorMessage(0);
                    }
                }
            }
        } else {
            setErrorMessage("Please select a valid .png or .jpeg image")
            if(isMobileDevice()){
                focusErrorMessage(0);
            }
        }
    }

    return (
        <Layout HQUrl={cprHqUrl}>
            <div className="layout_page_content page_1_layout_page_content">
                <h1 className="white_heading">You've accepted the CPR Challenge!</h1>
                <div className="dark_blue_content_container page_1_scroll_container">
                    <p className="step_text">
                        <span className="gold_text">Step 1: </span>
                        Send a personalized lifesaving CPR video
                        and tell your friends and family you are
                        making a difference.
                    </p>
                    <p className="step_text">
                        <span className="gold_text">Select a Heart Hero, Finn, or upload a photo to create a personalized lifesaving CPR video.</span>
                    </p>
                    <div className="character_selection_container">
                        <div className="character_selection_button upload_selection_button">
                            <div className="character_selection_radio_button_container">
                                <input
                                    id="uploadPhotoOption"
                                    type="radio"
                                    name="imageSelection" 
                                    onChange={() => imageOptionClicked('CUSTOM')} />
                                <label htmlFor="uploadPhotoOption">Upload a Photo</label>
                            </div>
                            {
                                imageSelection == 'CUSTOM' && (
                                    <>
                                        <input
                                            id="customPhotoInput"
                                            type="file"
                                            name="Find Photo"
                                            className=""
                                            visibility="hidden"
                                            aria-hidden="true"
                                            aria-label="Find Photo"
                                            onChange={(event) => {
                                                uploadPhoto(event)
                                            }}
                                        />
                                        {
                                            selectedImagePicked && (
                                                <>
                                                {
                                                    selectedImageLocalUrl != '' && (
                                                        <img id="your_photo_preview" src={selectedImageLocalUrl} alt="Your Photo" onLoad={() => imageLoadReply()} aria-label="Your Photo" />
                                                    )
                                                }
                                                    <button id="upload_button" className={imageSelection == 'CUSTOM' ? 'gold_button disabled_button' : 'gold_button'} onClick={() => uploadButtonClicked()}>Update Photo</button>
                                                </>
                                            )
                                        }
                                        {
                                            !selectedImagePicked && (
                                                <button id="upload_button" className={imageSelection == 'CUSTOM' ? 'gold_button disabled_button' : 'gold_button'} onClick={() => uploadButtonClicked()}>Find Photo</button>
                                            )
                                        }
        
                                        <p className="white_text">
                                            Upload a photo of your face. Ask an adult to help you find a JPG or PNG file.
                                        </p>
                                    </>
                                )
                            }
                        </div>
                        {includeDucks && (
                            <>
                                <div className="character_selection_button">
                                    <div className="character_selection_radio_button_container">
                                        <input
                                            id="aceOption"
                                            type="radio"
                                            name="imageSelection"
                                            onChange={() => imageOptionClicked('ACE')} />
                                        <label htmlFor="aceOption">Ace</label>
                                    </div>
                                    <img src={ace_image} alt="Ace" aria-label="Ace"/>
                                </div>
                                <div className="character_selection_button">
                                    <div className="character_selection_radio_button_container">
                                        <input
                                            id="wynnOption"
                                            type="radio"
                                            name="imageSelection"
                                            onChange={() => imageOptionClicked('WYNN')} />
                                        <label htmlFor="wynnOption">Wynn</label>
                                    </div>
                                    <img src={wynn_image} alt="Wynn" aria-label="Wynn"/>
                                </div>
                                <div className="character_selection_button">
                                    <div className="character_selection_radio_button_container">
                                        <input
                                            id="dakotaOption"
                                            type="radio"
                                            name="imageSelection"
                                            onChange={() => imageOptionClicked('DAKOTA')} />
                                        <label htmlFor="dakotaOption">Dakota</label>
                                    </div>
                                    <img src={dakota_image} alt="Dakota" aria-label="Dakota"/>
                                </div>
                            </>
                        )}  
                        <div className="character_selection_button">
                            <div className="character_selection_radio_button_container">
                                <input
                                    id="finnOption"
                                    type="radio"
                                    name="imageSelection"
                                    onChange={() => imageOptionClicked('FINN')} />
                                <label htmlFor="finnOption">Finn</label>
                            </div>
                            <img src={finn_image} alt="Finn" aria-label="Finn"/>
                        </div>
                    </div>
                </div>
                <div className="error_container">
                    <>
                        {
                            (errorMessage != '') && (
                               <div id="step_1_error" className="error_text step_text" onLoad={() => focusErrorMessage()} role="alert">{errorMessage}</div>
                            )
                        }
                    </>
                </div>
                <div className="page_content_buttons">
                    <>   
                        {
                            (!isUploading) && (
                                <>
                                {
                                    (imageSelection == 'FINN' || imageSelection == 'ACE' || imageSelection == 'WYNN' || imageSelection == 'DAKOTA' || selectedImagePicked) && (
                                        <button id="next_button" className="gold_button" onClick={() => { nextButtonClicked() }}>Next</button>
                                    )
                                }
                                {
                                    ((imageSelection == 'CUSTOM' && !selectedImagePicked)) && (
                                        <button className="hidden_button gold_button">Next</button>
                                    )
                                }
                            </>
                            )
                        }
                        {
                            (isUploading) && (
                                <button id="next_button" className="transparent_button gold_button" disabled>Uploading...</button>
                            )
                        }
                    </>

                </div>
            </div>
        </Layout>
    );
};

export default Page1;