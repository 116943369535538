import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../components/layout";
import "./page2.scss";
import { clearGlobalVar, getBaseUrl, getClient, getGlobalVar, setGlobalVar, focusElement, sendGoogleAnalytics } from "../../components/globalFunctions";
import play_icon from '../../img/play_icon.png'

const Page2 = ({ canEdit }) => {
    let { event_id } = useParams();
    let { cons_id } = useParams();

    const [cprHqUrl, setCprHqUrl] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        sendGoogleAnalytics('aha_cpr_s2_page_load', 'aha_cpr_s2')
        focusElement(document.getElementById('layout_hq_button'))
        if (videoUrl == "") {
            setCprHqUrl(getGlobalVar('cpr-hq-url'))
            loadVideo();
        }
        loadVideoEvent(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [videoUrl]);

    const loadVideoEvent = (i) => {
        var video = document.getElementsByTagName('video')[0];
        if (video) {
            video.onended = function (e) {
                nextStep()
            };
        } else {
            setTimeout(() => { loadVideoEvent(i + 1) }, (i * 50))
        }
    };

    const loadVideo = () => {
        let video_url = getGlobalVar('video_url');
        setVideoUrl(video_url);
        clearGlobalVar('cpr_video_watched')
    };

    const backButtonClicked = () => {
        navigate(`/editcprchallenge/${event_id}/${cons_id}/1/`)
        sendGoogleAnalytics('aha_cpr_s2_back_button', 'aha_cpr_s2')
    }

    const nextStep = () => {
        setGlobalVar('cpr_video_watched', 'true')
        sendGoogleAnalytics('aha_cpr_s2_video_watched', 'aha_cpr_s2')

        var url = `${getBaseUrl()}/${getClient()}/custom/aha/addAction/${cons_id}/${event_id}/cpr_quiz`;
        let token = getGlobalVar('jwt_auth_token');

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(url, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                if (result.success) {
                    setGlobalVar('cpr_video_watched', true)
                    navigate(`/editcprchallenge/${event_id}/${cons_id}/3/`)
                }
            })
            .catch(
                (error) => navigate(`/editcprchallenge/${event_id}/${cons_id}/5`)
            );
    }

    const skipButtonClicked = () => {
        setGlobalVar('cpr_video_watched', false)
        navigate(`/editcprchallenge/${event_id}/${cons_id}/3/`)
        sendGoogleAnalytics('aha_cpr_s2_skip_button', 'aha_cpr_s2')
    }

    return (
        <Layout HQUrl={cprHqUrl}>
            <div className="layout_page_content">
                <div className="dark_blue_content_container page_2_blue_container">
                    <p className="step_text">
                        <span className="gold_text">Step 2: </span>
                        Watch to earn the Hands-Only CPR badge.
                    </p>
                    <div className="page_2_video_container">
                        {
                            (videoUrl != "") && (
                                <>
                                    <video id="cpr_video" aria-label="Hands Only CPR Video" tabIndex="0" width="100%" height="100%" src={videoUrl + "#t=0.01"} frameBorder="0" controls allowFullScreen></video>
                                </>
                            )
                        }
                    </div>
                </div>
                <div className="page_2_buttons">
                    <button onClick={() => { backButtonClicked() }} className="gold_button">Back</button>
                    <button onClick={() => { skipButtonClicked() }} className="skip_button blue_button">Skip This Step</button>
                </div>
            </div>
        </Layout>
    );
};

export default Page2;