import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";

const Error = ({ }) => {
    return (
        <Layout HQUrl={''}>
            <div className="layout_page_content error_page">
                <p className="white_text">The user you have entered is invalid. Please try again.</p>
            </div>
        </Layout>
    );
};

export default Error;