import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../components/layout";
import { getBaseUrl, getClient, setGlobalVar, clearGlobalVar, getGoogleAnalyticsId } from "../../components/globalFunctions";

import ReactGA from "react-ga4";

export const initGA = trackingID => {
    ReactGA.initialize(trackingID);
};

(function initAnalytics() {
    initGA(getGoogleAnalyticsId());
})();

const IndexPage = ({}) => {
    let { event_id } = useParams();
    let { cons_id } = useParams();
    let { auth_token } = useParams();
    let { session_id } = useParams();
    let { sso_type } = useParams();

    const navigate = useNavigate();

    const [apiLoaded, setApiLoaded] = useState(false)

    useEffect(() => {
        if (!apiLoaded) {
            ssoLogin();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiLoaded]);

    const ssoLogin = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const urlencoded = new URLSearchParams();
        urlencoded.append("sso_token", auth_token);
        urlencoded.append("cons_id", cons_id);
        urlencoded.append("event_id", event_id);
        urlencoded.append("session_id", session_id);
        if(sso_type == 'sso'){
            urlencoded.append("use_user_sso", "true");
        }

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow"
        };

        fetch(`${getBaseUrl()}/${getClient()}/user/ssoLoginStandard`, requestOptions)
            .then((response) => response.json())
            .then(data => {
                setApiLoaded(true)
                setGlobalVar('jwt_auth_token', data.data.jwt)
                loadUrls()
            })
            .catch((error) => {
                clearGlobalVar('jwt_auth_token')
                navigate(`/editcprchallenge/${event_id}/${cons_id}/`)
            });
    };

    const loadUrls = () => {
        //add api call heres
        var options = {
            method: "GET",
        };

        var url = `${getBaseUrl()}/${getClient()}/ai/cprStatus/${cons_id}/${event_id}`;
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                setGlobalVar('cpr-hq-url', data.success.cpr_hq_url)
                if (data.success.status == "NOTCREATED") {
                    navigate(`/editcprchallenge/${event_id}/${cons_id}/1`)
                } else {
                    navigate(`/editcprchallenge/${event_id}/${cons_id}/5`)
                }
            })
    };

    return (
        <Layout HQUrl={''}>
        </Layout>
    );
};

export default IndexPage;