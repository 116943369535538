import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../components/layout";
import "./page3.scss";
import { getBaseUrl, getClient, getGlobalVar, setGlobalVar, isMobileDevice, focusElement, sendGoogleAnalytics } from "../../components/globalFunctions";
import play_icon from '../../img/play_icon.png'
import { useSubscription } from '@apollo/client';
import { gql } from '@apollo/client';

const Page3 = ({}) => {
    let { event_id } = useParams();
    let { cons_id } = useParams();

    const [cprHqUrl, setCprHqUrl] = useState('');
    const [blankVideoUrl, setBlankVideoUrl] = useState('');
    const [customVideoUrl, setCustomVideoUrl] = useState('');
    const [shareVideoUrl, setShareVideoUrl] = useState('');
    const [shareDonationsUrl, setShareDonationsUrl] = useState('')
    const [isPlaying, setIsPlaying] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [hasWatched, setHasWatched] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const [videoProcessing, setVideoProcessing] = useState(false);
    const [subject, setSubject] = useState('')

    const navigate = useNavigate();

    useEffect(() => {
        sendGoogleAnalytics('aha_cpr_s3_page_load', 'aha_cpr_s3')
        focusElement(document.getElementById('layout_hq_button'))
        if (shareVideoUrl == "") {
            setCprHqUrl(getGlobalVar('cpr-hq-url'))
            let video_watched = getGlobalVar('cpr_video_watched', 'false')
            setHasWatched(video_watched)
            loadUrls();
            setIsMobile(isMobileDevice().is_mobile)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shareVideoUrl]);

    const loadVideoEvent = (id, i) => {
        var video = document.getElementById(id)
        if (video) {
            video.onended = function (e) {
                nextStep()
            };
        } else {
            setTimeout(() => {loadVideoEvent(id, i + 1)}, (i * 50))
        }
    };

    useEffect(() => {
        addViewCount('page_view')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const addViewCount = (type) => {
        let url = '';

        var options = {
            method: "GET",
        };

        if(type == 'watch'){
            url = `${getBaseUrl()}/${getClient()}/ai/incrementVideoCount/${cons_id}/${event_id}/watch_count`;
        }else if(type == 'page_view'){
            url = `${getBaseUrl()}/${getClient()}/ai/incrementVideoCount/${cons_id}/${event_id}/view_count`;
        }

        fetch(url, options)
        .then(response => response.json())
        .then(data => {
            //console.log(data)
        })
    };

    const loadUrls = () => {
        var options = {
            method: "GET",
        };

        var url = `${getBaseUrl()}/${getClient()}/ai/cprStatus/${cons_id}/${event_id}`;
        fetch(url, options)
        .then(response => response.json())
        .then(data => {
            let response = data.success
            setBlankVideoUrl(response.video_url)
            if(response.video_url.includes("FINN") || response.video_url.includes('finn') 
            || response.video_url.includes("ACE") || response.video_url.includes('ace')
            || response.video_url.includes("DAKOTA") || response.video_url.includes('dakota')
            || response.video_url.includes("WYNN") || response.video_url.includes('wynn')){
                setCustomVideoUrl(response.video_url)
            }
            setFirstName(response.first_name)
            setSubject(`${response.first_name} shared this lifesaving CPR video with you!`)
            getShareUrl()
            getDonationsUrl(response.donations_page_url)

            if (response.status == "COMPLETED") {
                setVideoProcessing(false)
            } else {
                setVideoProcessing(true)
            }
        })
    };

    const shareButtonClicked = () => {
        sendShareCall()
        sendGoogleAnalytics('aha_cpr_s3_share_button', 'aha_cpr_s3')
        const shareData = {
            title: `${firstName} shared this lifesaving CPR video with you!`,
            text: `Watch ${firstName}’s lifesaving CPR video ${shareVideoUrl} and support ${firstName} with a donation today! ${shareDonationsUrl}.`,
          };

        if (navigator.share && navigator.canShare(shareData)) {
            try{
                navigator.share(shareData)
            }catch(err){
                setIsMobile(false)
            }
         } else {
            setIsMobile(false)
         }
    };

    const sendShareCall = () => {
        var url = `${getBaseUrl()}/${getClient()}/custom/aha/addAction/${cons_id}/${event_id}/quiz_share_email`;
        let token = getGlobalVar('jwt_auth_token');

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {})  
        .catch();
    };

    const getShareUrl = () => {
        let video_url = window.location.href.replace('editcprchallenge','cprchallenge').replace('/3/','')
        
        var url = `${getBaseUrl()}/${getClient()}/events/getCustomTinyUrl`;
        let token = getGlobalVar('jwt_auth_token');

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        const urlencoded = new URLSearchParams();
        urlencoded.append("url", video_url);
        urlencoded.append("event_id", event_id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
        };

        fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            if(result.data){
                setShareVideoUrl(result.data)
            }
        })  
        .catch();
    };

    const getDonationsUrl = (video_url) => {
        var url = `${getBaseUrl()}/${getClient()}/events/getCustomTinyUrl`;
        let token = getGlobalVar('jwt_auth_token');

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        const urlencoded = new URLSearchParams();
        urlencoded.append("url", video_url);
        urlencoded.append("event_id", event_id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
        };

        fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            if(result.data){
                setShareDonationsUrl(result.data)
            }
        })  
        .catch();
    };

    const backButtonClicked = () => {
        sendGoogleAnalytics('aha_cpr_s3_back_button', 'aha_cpr_s3')
        navigate(`/editcprchallenge/${event_id}/${cons_id}/2/`)
    }

    const nextStep = () => {
        sendGoogleAnalytics('aha_cpr_s3_next_button', 'aha_cpr_s3')
        setGlobalVar('cpr_video_watched', true)
        let video = document.getElementById('cpr_video');
        setIsPlaying(false);
        video.pause();
        video.currentTime = 0;

        var url = `${getBaseUrl()}/${getClient()}/custom/aha/addAction/${cons_id}/${event_id}/cpr_quiz`;
        let token = getGlobalVar('jwt_auth_token');

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            if (result.success) {
                setGlobalVar('cpr_video_watched', true)
                setHasWatched('true')
                addViewCount('watch');
            }
        })
        .catch();
    }

    const VIDEO_PROCESSING_SUBSCRIPTION = gql`
        subscription videoProcessingCompleted($cons_id: Float!, $event_id: Float!, $client: String!) {
            videoProcessingCompleted(cons_id: $cons_id, event_id: $event_id, client: $client) {
                cons_id,
                event_id
                image_url,
                video_url,
                status,
                first_name,
                personal_page_url,
                donations_page_url,
                cpr_hq_url,
            }
        }
    `;

    let onError = (error) => {
        console.log(error)
    }

    let onData = (result) => {
        if(result.data?.data?.videoProcessingCompleted?.video_url){
            setVideoProcessing(false)
            setCustomVideoUrl(result.data.data.videoProcessingCompleted.video_url)
        }
    }

    const { data, loading, error } = useSubscription(
        VIDEO_PROCESSING_SUBSCRIPTION,
        { variables: { 
            cons_id: parseInt(cons_id), 
            event_id: parseInt(event_id), 
            client: getClient() 
        }, skip: !videoProcessing, onError, onData  }
    );

    return (
        <Layout HQUrl={cprHqUrl}>
            <div className="layout_page_content">
                <div className="dark_blue_content_container page_3_blue_container">
                    <p className="step_text step_text_3">
                        <span className="gold_text">Step 3: </span>
                        Share your lifesaving CPR video with friends and family today!
                    </p>
                    <div className="donation_container">
                    {
                        (isMobile && shareVideoUrl != "") && (
                            <button onClick={() => {shareButtonClicked()}} className="red_button share_button">Share</button>
                        )
                    }
                    {
                        (!isMobile && shareVideoUrl != "") && (
                            <a onClick={() => sendShareCall()} href={`mailto:?subject=${subject}&body=Watch ${firstName}’s lifesaving CPR video ${shareVideoUrl} and support ${firstName} with a donation today! ${shareDonationsUrl}`} target="_blank" role="button" className="red_button share_button">Share</a>
                        )
                    }
                    </div>
                    {
                        (videoProcessing == true) && (
                            <p className="five_text step_text">
                                <span className="gold_text">We're hard at work processing the personalized CPR video. The video will automatically display when ready. If the video doesn't display, please refresh the browser.</span>
                            </p>
                        )
                    }
                    <div className="page_3_video_container">
                    {
                        (videoProcessing) && (
                            (blankVideoUrl != "") && (
                                <video id="blank_cpr_video" aria-label="Hands Only CPR Video" tabIndex="0" onLoadedData={() => {loadVideoEvent("blank_cpr_video", 0)}} controls width="100%" height="100%" src={blankVideoUrl + "#t=0.01"} frameBorder="0" allowFullScreen></video>
                            )
                        )
                    }
                    {
                        (!videoProcessing) && (
                            (customVideoUrl != "") && (
                                <video id="cpr_video" aria-label="Hands Only CPR Video" tabIndex="0" onLoadedData={() => {loadVideoEvent("cpr_video", 0)}} controls width="100%" height="100%" src={customVideoUrl + "#t=0.01"} frameBorder="0" allowFullScreen></video>
                            )
                        )
                    } 
                    </div>
                    {
                        (hasWatched == 'true') && (
                            <p className="step_text step_text_3" aria-live="polite">
                                Congratulations you earned the Hands-Only CPR badge!
                            </p>
                        )
                    }
                    {
                        (hasWatched == 'false') && (
                            <p className="step_text step_text_3">
                                Watch to earn the Hands-Only CPR badge.
                            </p>
                        )
                    }
                </div>
                <div className="page_3_buttons">
                    <button onClick={() => { backButtonClicked() }} className="gold_button">Back</button>
                </div>
            </div>
        </Layout>
    );
};

export default Page3;