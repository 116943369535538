import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Layout from "../../components/layout";
import "./page5.scss";
import { clearGlobalVar, getBaseUrl, getClient, setGlobalVar, getGlobalVar, focusElement, sendGoogleAnalytics, isMobileDevice } from "../../components/globalFunctions";
import { useSubscription } from '@apollo/client';
import { gql } from '@apollo/client';

const Page5 = ({ canEdit }) => {
    let { event_id } = useParams();
    let { cons_id } = useParams();

    const [personalPageUrl, setPersonalPageUrl] = useState('');
    const [donationsPageUrl, setDonationsPageUrl] = useState('');
    const [firstName, setFirstName] = useState('');
    const [cprHqUrl, setCprHqUrl] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [isMobile, setIsMobile] = useState(false);
    const [videoProcessing, setVideoProcessing] = useState(false);
    const [shareDonationsUrl, setShareDonationsUrl] = useState('')
    const [shareVideoUrl, setShareVideoUrl] = useState('');
    const [subject, setSubject] = useState('')
    const [showModal, setShowModal] = useState(false);

    const [apiLoaded, setApiLoaded] = useState(false);

    const navigate = useNavigate();

    const VIDEO_PROCESSING_SUBSCRIPTION = gql`
        subscription videoProcessingCompleted($cons_id: Float!, $event_id: Float!, $client: String!) {
            videoProcessingCompleted(cons_id: $cons_id, event_id: $event_id, client: $client) {
                cons_id,
                event_id
                image_url,
                video_url,
                status,
                first_name,
                personal_page_url,
                donations_page_url,
                cpr_hq_url,
            }
        }
    `;

    let onError = (error) => {
        console.log(error)
    }

    let onData = (result) => {
        if(result.data?.data?.videoProcessingCompleted?.video_url){
            setVideoProcessing(false)
            setVideoUrl(result.data.data.videoProcessingCompleted.video_url)
        }
    }

    const donateLinkClicked = () => {
        sendGoogleAnalytics('aha_cpr_s5_donate_link', 'aha_cpr_s5')
        return true;
    };

    const visitLinkClicked = () => {
        sendGoogleAnalytics('aha_cpr_s5_visit_page_link', 'aha_cpr_s5')
        return true;
    };

    const modalDonateLinkClicked = () => {
        sendGoogleAnalytics('aha_cpr_s5_modal_donate_link', 'aha_cpr_s5')
        return true;
    };

    const modalVisitLinkClicked = () => {
        sendGoogleAnalytics('aha_cpr_s5_modal_visit_page_link', 'aha_cpr_s5')
        return true;
    };

    const sendShareCall = () => {
        var url = `${getBaseUrl()}/${getClient()}/custom/aha/addAction/${cons_id}/${event_id}/quiz_share_email`;
        let token = getGlobalVar('jwt_auth_token');

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
        };

        fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {})  
        .catch();
    };

    const { data, loading, error } = useSubscription(
        VIDEO_PROCESSING_SUBSCRIPTION,
        { variables: { 
            cons_id: parseInt(cons_id), 
            event_id: parseInt(event_id), 
            client: getClient() 
        }, skip: !videoProcessing, onError, onData  }
    );

    useEffect(() => {
        addViewCount('page_view')
        sendGoogleAnalytics('aha_cpr_s5_page_load', 'aha_cpr_s5')
        setIsMobile(isMobileDevice().is_mobile)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        focusElement(document.getElementById('layout_hq_button'))
        if (!apiLoaded) {
            loadUrls();
            loadVideoEvent(0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiLoaded]);

    const addViewCount = (type) => {
        let url = '';

        var options = {
            method: "GET",
        };

        if(type == 'watch'){
            url = `${getBaseUrl()}/${getClient()}/ai/incrementVideoCount/${cons_id}/${event_id}/watch_count`;
        }else if(type == 'page_view'){
            url = `${getBaseUrl()}/${getClient()}/ai/incrementVideoCount/${cons_id}/${event_id}/view_count`;
        }

        fetch(url, options)
        .then(response => response.json())
        .then(data => {
            //console.log(data)
        })
    };

    const loadUrls = () => {
        var options = {
            method: "GET",
        };

        clearGlobalVar('imageSelection')
        clearGlobalVar('selectedImageType')
        clearGlobalVar('selectedImageLocalUrl')
        clearGlobalVar('selectedImageData')

        var url = `${getBaseUrl()}/${getClient()}/ai/cprStatus/${cons_id}/${event_id}`;
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                let response = data.success
                setPersonalPageUrl(response.personal_page_url)
                setDonationsPageUrl(response.donations_page_url)
                setFirstName(response.first_name)
                setCprHqUrl(response.cpr_hq_url)
                setGlobalVar('cpr-hq-url', response.cpr_hq_url)
                setVideoUrl(response.video_url)
                setApiLoaded(true)
                setSubject(`${response.first_name} shared this lifesaving CPR video with you!`)
                if(canEdit){
                    getShareUrl()
                    getDonationsUrl(response.donations_page_url)
                }
                if (response.status == "COMPLETED") {
                    setVideoProcessing(false)
                } else {
                    setVideoProcessing(true)
                }
            })
    };

    const shareButtonClicked = () => {
        sendShareCall()
        sendGoogleAnalytics('aha_cpr_s3_share_button', 'aha_cpr_s3')
        const shareData = {
            title: `${firstName} shared this lifesaving CPR video with you!`,
            text: `Watch ${firstName}’s lifesaving CPR video ${shareVideoUrl} and support ${firstName} with a donation today! ${shareDonationsUrl}.`,
          };

        if (navigator.share && navigator.canShare(shareData)) {
            try{
                navigator.share(shareData)
            }catch(err){
                setIsMobile(false)
            }
         } else {
            setIsMobile(false)
         }
    }

    const getShareUrl = () => {
        let video_url = window.location.href.replace('editcprchallenge','cprchallenge').replace('/5/','')
        
        var url = `${getBaseUrl()}/${getClient()}/events/getCustomTinyUrl`;
        let token = getGlobalVar('jwt_auth_token');

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        const urlencoded = new URLSearchParams();
        urlencoded.append("url", video_url);
        urlencoded.append("event_id", event_id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
        };

        fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            console.log("RESULT")
            console.log(result)
            console.log(result.data)
            if(result.data){
                setShareVideoUrl(result.data)
            }
        })  
        .catch();
    };

    const getDonationsUrl = (video_url) => {
        var url = `${getBaseUrl()}/${getClient()}/events/getCustomTinyUrl`;
        let token = getGlobalVar('jwt_auth_token');

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        
        const urlencoded = new URLSearchParams();
        urlencoded.append("url", video_url);
        urlencoded.append("event_id", event_id);

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
        };

        fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            if(result.data){
                setShareDonationsUrl(result.data)
            }
        })  
        .catch();
    };

    const loadVideoEvent = (i) => {
        var video = document.getElementsByTagName('video')[0];
        if (video) {
            video.onended = function (e) {
                addViewCount('watch');
                setShowModal(true);
                document.getElementById('layout_content_container').setAttribute('aria-hidden',true)
                focusElement('close_button',0);
                if(canEdit){
                    sendVideoWatchedAction()
                }
            };
        } else {
            setTimeout(() => { loadVideoEvent(i + 1) }, (i * 50))
        }
    };

    const sendVideoWatchedAction = () => {
        var url = `${getBaseUrl()}/${getClient()}/custom/aha/addAction/${cons_id}/${event_id}/cpr_quiz`;
        let token = getGlobalVar('jwt_auth_token');

        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + token);
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        fetch(url, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            if (result.success) {}
        })
        .catch(
            (error) => navigate(`/editcprchallenge/${event_id}/${cons_id}/5`)
        );
    }

    const hideModal = () => {
        document.getElementById('layout_content_container').removeAttribute('aria-hidden');
        setShowModal(false);
    }

    const editButtonClicked = () => {
        sendGoogleAnalytics('aha_cpr_s5_edit_button', 'aha_cpr_s5')
        navigate(`/editcprchallenge/${event_id}/${cons_id}/1/`)
    }

    const modalBlur = (event, id) => {
        if(event.relatedTarget == null){
            document.getElementById(id).focus();
        }
        if(event.relatedTarget){
            if(event.relatedTarget.getAttribute('data-modal') != 'true'){
                document.getElementById(id).focus();
            }
        }else{
            document.getElementById(id).focus();
        }
    }

    const handleEnterClicked = (event, element) => {
         if(event.keyCode == 13 || event.keyCode == 32){
            element.click()
         }
    }

    return (
        <>
            {
                (showModal == true) && (
                    <div className="layout_modal_container">
                    <div className="layout_modal">
                        <div className="close_row">
                            <div id="close_button" className="close_button" data-modal="true" tabIndex={0} role="button" onKeyDown={(event) => { handleEnterClicked(event, document.getElementById('close_button')) }} onBlur={(event) => { modalBlur(event,'visit_button') }} aria-label="Close" onClick={() => {hideModal();}}>
                                <span>Close</span>
                                <div className="close_icon">
                                    <p>&#10005;</p>
                                </div>
                            </div>
                        </div>
                        <div className="layout_modal_text" data-modal="true">
                            Support {firstName} by making a donation today!
                        </div>
                        <div className="layout_modal_buttons">
                            <a data-modal="true" href={donationsPageUrl} target="_blank" onClick={() => modalDonateLinkClicked()} className="modal_button gold_button">Donate</a>
                            <a data-modal="true" id="visit_button" tabIndex={0} onClick={() => modalVisitLinkClicked()} href={personalPageUrl} onBlur={(event) => { modalBlur(event,'close_button') }} target="_blank" className="modal_button blue_button">Visit {firstName}'s Page</a>
                        </div>
                    </div>
                </div>
                )
            }
            <Layout HQUrl={cprHqUrl}>
                <div className="layout_page_content">
                    {
                        (!canEdit) && (
                            <h1 className="white_subheading">{firstName} shared this lifesaving CPR video with you!</h1>
                        )
                    }
                                        {
                        (canEdit) && (
                            <div className="heading_spacer_page_1"></div>
                        )
                    }
                    <div className="donation_container">
                        <p className="donation_text">
                            Support {firstName} by making a donation!
                        </p>
                        <a href={donationsPageUrl} target="_blank" onClick={() => donateLinkClicked()} className="red_button donate_button">Donate</a>
                    </div>
                    {
                        (videoProcessing == true) && (
                            <p className="five_text step_text">
                                <span className="gold_text">We’re hard at work processing the personalized CPR video. The video will automatically display when ready.</span>
                            </p>
                        )
                    }
                    <div className="page_5_video_container">
                        {
                            (videoUrl != "") && (
                                <video id="page_5_cpr_video" aria-label="Hands Only CPR Video" height="100%" src={videoUrl + "#t=0.01"} frameBorder="0" controls></video>
                            )
                        }
                    </div>
                    <div className="page_share_content_buttons">
                        <>
                            <a href={personalPageUrl} target="_blank" id="visit_link" onClick={() => visitLinkClicked()} className="gold_button">Visit {firstName}'s Page </a>
                            {
                                (canEdit) && (
                                    <>
                                        {
                                            (isMobile && shareVideoUrl != "") && (
                                                <button onClick={() => {shareButtonClicked()}} className="red_button share_button">Share</button>
                                            )
                                        }
                                        {
                                            (!isMobile && shareVideoUrl != "") && (
                                                <a onClick={() => sendShareCall()} href={`mailto:?subject=${subject}&body=Watch ${firstName}’s lifesaving CPR video ${shareVideoUrl} and support ${firstName} with a donation today! ${shareDonationsUrl}`} target="_blank" role="button" className="red_button share_button">Share</a>
                                            )
                                        }
                                        <button onClick={() => { editButtonClicked() }} id="edit_video_button" className="red_button">Edit</button>
                                    </>
                                )
                            }
                        </>
                    </div>
                    <div className="mobile_page_share_content_buttons">
                        <>
                            <a href={personalPageUrl} target="_blank" id="visit_link" onClick={() => visitLinkClicked()} className="gold_button">Visit {firstName}'s Page </a>
                            {
                                (canEdit) && (
                                    <>
                                        {
                                            (isMobile && shareVideoUrl != "") && (
                                                <button onClick={() => {shareButtonClicked()}} className="red_button share_button">Share</button>
                                            )
                                        }
                                        {
                                            (!isMobile && shareVideoUrl != "") && (
                                                <a onClick={() => sendShareCall()} href={`mailto:?subject=${subject}&body=Watch ${firstName}’s lifesaving CPR video ${shareVideoUrl}and support ${firstName} with a donation today! ${shareDonationsUrl}`} target="_blank" role="button" className="red_button share_button">Share</a>
                                            )
                                        }
                                        <button onClick={() => { editButtonClicked() }} id="edit_video_button" className="red_button">Edit</button>
                                    </>
                                )
                            }
                        </>
                    </div>
                </div>
            </Layout>
        </>
    );
};

export default Page5;