import React from "react";
import "./layout.scss"
import logo from '../img/logo-container-with-logo.png';
import { sendGoogleAnalytics } from '../components/globalFunctions'

const linkClicked = () => {
    sendGoogleAnalytics('aha_cpr_hq_url_button', 'aha_cpr_header')
    return true;
};

const Layout = (props) => {
    return (
        <div className="layout_content_container" id="layout_content_container">
            <div className="layout_scroll_content_container">
                <div className="layout_mobile_background"></div>
                <div className="layout_background"></div>
                <div className="layout_logo_container">
                    <img alt="American Heart Association Kids Heart Challenge American Heart Challenge Logo" src={logo} />
                </div>
                {
                    (props.HQUrl != '') && (
                        <a href={props.HQUrl} onClick={() => linkClicked()} aria-label='back to hq' id="layout_hq_button" className="red_button">Back to HQ</a>
                    )
                }
                <div id="layout_content" className="layout_content">
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default Layout;