import ReactGA from 'react-ga4';

export function getBaseUrl(){
    return process.env.REACT_APP_BASE_URL
}

export function getClient(){
    return process.env.REACT_APP_CLIENT_NAME
}

export function getGoogleAnalyticsId(){
    return process.env.REACT_APP_GA_ID
}

export function sendGoogleAnalytics(event_name, page){
    console.log("Sending Google Analytics: " + event_name + " - " + page) 
    ReactGA.event({
        category: page,
        action: event_name,
        label: page, 
    });
}

export function setGlobalVar(key, value) {
    sessionStorage.setItem(key, value)
}

export function getGlobalVar(key, defaultValue="") {
    if (typeof window !== "undefined") {
      const saved = sessionStorage.getItem(key);
      const initial = saved !== null ? saved : defaultValue;
      return initial;
    }else{
        return defaultValue
    }
}

export function clearGlobalVar(key) {
    sessionStorage.removeItem(key)
}

export function isMobileDevice(){
    let return_val = {
        type: 'desktop',
        is_mobile: false,
    };

    if(navigator.userAgent.match(/Android/i)){
        return_val = {
            type: 'Android',
            is_mobile: true,
        };
    } else if(navigator.userAgent.match(/iPhone|iPad|iPod/i)){
        return_val = {
            type: 'iOS',
            is_mobile: true,
        };
    } else if(navigator.userAgent.match(/BlackBerry/i)){
        return_val = {
            type: 'BlackBerry',
            is_mobile: true,
        };
    } else if(navigator.userAgent.match(/Opera Mini/i)){
        return_val = {
            type: 'Opera',
            is_mobile: true,
        };
    } else if(navigator.userAgent.match(/IEMobile/i) || navigator.userAgent.match(/WPDesktop/i)){
        return_val = {
            type: 'WindowsPhone',
            is_mobile: true,
        };
    };
    
    return return_val;
}

export function focusElement(id, index) {
    if(index < 10){
        if(document.getElementById(id)){
            document.getElementById(id).focus()
        }else{
            setTimeout(() => {focusElement(id, index + 1)}, 50)
        }
    }
}