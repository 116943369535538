import React, { useState, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import './App.scss';
import { BrowserRouter as Router, Routes, Route, Navigate }
  from 'react-router-dom';
import Page1 from "./pages/page1/page1";
import Page2 from "./pages/page2/page2";
import Page3 from "./pages/page3/page3";
import Page5 from "./pages/page5/page5";
import Error from './pages/error/error';
import IndexPage from './pages/index';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { getBaseUrl } from './components/globalFunctions';
import { getGoogleAnalyticsId } from './components/globalFunctions'
import ReactGA from 'react-ga4';

const TRACKING_ID = getGoogleAnalyticsId();
ReactGA.initialize(TRACKING_ID);

function App() {
  const wsLink = new GraphQLWsLink(createClient({
    url: `${getBaseUrl()}/api/ai/graphql`,
  }));

  const client = new ApolloClient({
    link: wsLink,
    cache: new InMemoryCache(),
  });
  return (
    <>
      <ApolloProvider client={client}>
        <Router>
          <Routes>
            <Route path='/editcprchallenge/:event_id/:cons_id/:auth_token/:session_id'element={<IndexPage/>} />
            <Route path='/editcprchallenge/:event_id/:cons_id/:auth_token/:session_id/:sso_type'element={<IndexPage/>} />
            <Route path='/editcprchallenge/:event_id/:cons_id/' element={<Page5 canEdit={false}/>} />
            <Route path='/cprchallenge/:event_id/:cons_id/' element={<Page5 canEdit={false}/>} />

            <Route path='/editcprchallenge/:event_id/:cons_id/1'element={<Page1/>} />
            <Route path='/editcprchallenge/:event_id/:cons_id/2'element={<Page2/>} />
            <Route path='/editcprchallenge/:event_id/:cons_id/3'element={<Page3/>} />
            <Route path='/editcprchallenge/:event_id/:cons_id/5' element={<Page5 canEdit={true}/>} />
            <Route path='/' element={<Error/>} />
            <Route path="*" element={<Navigate to="/" />}/>
          </Routes>
        </Router>
      </ApolloProvider>
    </>
  );
}

export default App;